@font-face {
  font-family: 'RT';
  src: url('RostelecomBasis-Regular.otf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'RT';
  src: url('RostelecomBasis-Medium.otf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'RT';
  src: url('RostelecomBasis-Bold.otf');
  font-weight: 600;
  font-style: normal;
}