@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./shared/assets/fonts/font.css";
@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:wght@600&display=swap');

body {
  font-family: 'RT', sans-serif;
}


@supports (-webkit-touch-callout: none) {
  .min-h-screen {
    min-height: -webkit-fill-available;
  }
}

.font-lottery {
  font-family: 'Azeret Mono', monospace;
}

.animate-show-scale {
  animation: anim-show-scale 1s ease-in-out 1 both;
}

.animate-show-from-top {
  animation: anim-show-from-top 1s ease-in-out 1 both;
}

.animate-delay-100 {
  animation-delay: 100ms;
}
.animate-delay-200 {
  animation-delay: 200ms;
}
.animate-delay-300 {
  animation-delay: 300ms;
}
.animate-delay-400 {
  animation-delay: 400ms;
}
.animate-delay-500 {
  animation-delay: 500ms;
}
.animate-delay-600 {
  animation-delay: 600ms;
}
.animate-delay-700 {
  animation-delay: 700ms;
}
.animate-delay-800 {
  animation-delay: 800ms;
}
.animate-delay-900 {
  animation-delay: 900ms;
}
.animate-delay-1000 {
  animation-delay: 1000ms;
}


@keyframes anim-show-from-top {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-show-scale {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.animate-scale {
  animation: anim-scale 3s ease infinite both;
}

@keyframes anim-scale {
  0% {
    transform: scale(0.9);
  }
  50%{
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.9);
  }
}



.animate-hide-opacity {
  animation: hide-opacity 1s ease 1 both;
}

@keyframes hide-opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.animate-show-opacity {
  animation: show-opacity 1s ease 1 both;
}

@keyframes show-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animate-hide-to-top {
  animation: hide-to-top 1s ease 1 both;
}

@keyframes hide-to-top {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-200%);
  }
}

.animate-hide-to-bottom {
  animation: hide-to-bottom 1s ease 1 both;
}

@keyframes hide-to-bottom {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(200%);
  }
}